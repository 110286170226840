/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-02-19",
    versionChannel: "nightly",
    buildDate: "2023-02-19T00:19:23.615Z",
    commitHash: "89792865d0e79b63ba08e714a048d70a3e70b150",
};
